<template>
  <b-row style="background-color: #F5F7FA; padding-bottom: 50px;" class="d-flex justify-content-center content">
    <b-col style="max-width: 1200px;">

      <the-modal v-show="deleteConfirmActive" style="position: absolute; width: 100%; z-index: 500;">

          <div @click="closeEverything" style="left: 0px; position: fixed; height: 100%; width: 100%;"></div>

          <div style="position: aboslute; max-width: 450px; margin-left: auto; margin-right: auto; z-index: 360; margin-top: 50px;">

            <div class="row">
              <div class="col-lg-12 bg-white text-center" style="padding-bottom: 30px; border-radius: 10px;">
                <h1 style="font-size: 25px; font-weight: 400; margin-top: 20px;">Are you sure?</h1>
                <p style="font-weight: 500; margin-top: -5px;">You are about to delete an inquiry from: <strong>{{ targetedInquiry.name }}</strong></p>
                <b-button @click="deleteInquiry(targetedInquiry.inquiry_id)" v-show="!processing" type="button" class="btn btn-danger btn-dash-submit">Yes, Delete</b-button>
                <b-button v-show="processing" disabled type="button" class="btn btn-primary btn-dash-submit"><b-spinner class="mr-2" small></b-spinner> Deleting...</b-button>
                <b-button @click="closeEverything" class="btn btn-light ml-3">Cancel</b-button>
              </div>
            </div>
          </div>
        </the-modal>

      <b-row>

        <b-col lg="12" class="mb-4">
          <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
            <b-card-body style="min-height: 150px;">
              <h1 style="font-size: 20px; margin-bottom: 25px; font-weight: 600;">Inquiries 
                <span style="background-color: rgb(23, 92, 142); margin-left: 10px; padding: 5px; border-radius: 20px; color: white; font-weight: 400; height: 25px; min-width: 25px; text-align: center; font-size: 14px;">{{ inquiriesCount }}</span>
              </h1>
              <table class="table table-striped">
                <tbody>
                  <tr class="small" v-for="(inquiry,index) in inquiries" :key="index">
                    <td width="250" class=""><strong>{{ inquiry.name }}</strong><br>{{ inquiry.role }}, {{ inquiry.dealership_name }}</td>
                    <td class="">{{ inquiry.phone }}<br>{{ inquiry.email }}</td>
                    <td class="">{{ inquiry.message }}</td>
                    <td width="180" class="text-right">{{ inquiry.create_date | moment("from", "now") }}</td>
                    <td v-show="showDeleteButton" width="80px;" class="">
                      <a @click.prevent="toggleDeleteConfirm(index)" class="float-right small p-1 btn-delete" href="#">Delete</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-card>
        </b-col>        

      </b-row>
    </b-col>
  </b-row>

</template>

<script>

  import TheModal from '@/components/TheModal.vue'
  
  export default {

  name: 'inquiries',

  components: {
    TheModal,
  },

  metaInfo: {
    title: 'Inquiries'
  },

  created () {
    this.getinquiries();
  },

  data() {
    return {
      inquiries: {},
      targetedInquiry: {},
      processing: false,
    }
  },

  methods: {

    getinquiries(){
      this.$store.commit('generateApiCreds');
      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'inquiries', {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          this.inquiries = resp.data;
        })
        // .catch(err => {
        //   alert('We have an error. Please contact support.' + err)
        //   console.log(err);
        // });
    },

    toggleDeleteConfirm(index) {
      window.scrollTo(0,0);
      this.updateTargetedInquiry(index);
      this.$store.commit("toggleModal");
      this.$store.commit("toggleDeleteConfirm");
    },

    updateTargetedInquiry(index) {
      this.targetedInquiry = this.inquiries[index];
    },

    deleteInquiry(inquiry_id){
      this.processing = true;
      this.$store.commit("generateApiCreds");
      const self = this;

      let url = process.env.VUE_APP_API_PATH + "inquiries/" + inquiry_id;

      this.$axios.delete(url, { 
        auth: {
          // this is to make sure our vue app is authorized
          username: this.$store.state.auth.apiUsername,
          password: this.$store.state.auth.apiPassword,
        },
        data: { 
          // this is to make sure our user is authorized
          user_id: this.$store.state.auth.userId,
          token: this.$store.state.auth.token,
        },
      })
      .then(function() {
        self.getinquiries();
        self.processing = false;
        self.$store.commit("closeEverything");
      })
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    }

  },

  computed: {

    inquiriesCount: function() {
      return this.inquiries.length;
    },

    deleteConfirmActive() {
      return this.$store.state.deleteConfirmActive ? true : false;
    },

    showDeleteButton() {
      if(this.$store.state.auth.userId == 100078) {
        return true;
      } else {
        return false;
      }
    }

  },

};

</script>

<style scoped>

.plus-icon {
  height: 40px;
  width: 40px;
  background-color: rgb(245, 247, 250);
  color: #212529;
  border-radius: 25px;
  float: right;
}

.plus-icon:hover {
  background-color: rgb(23, 92, 142);
  cursor: pointer;
  color: white;
}

.widget-item {
  text-decoration: none;
}

.widget-item:hover {
  background-color: grey;
}

.table > tbody > tr:first-child > td {
    border: none;
}

.btn-dashboard {
  color: #6c757d;
  padding: 5px;
}

.btn-dashboard:hover {
  color: white;
  border-radius: 5px;
  background-color: rgb(23, 92, 142);
  text-decoration: none;
}

.content {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {

  .content {
    margin-top: 0px;
    padding-top: 40px;
  }

}


</style>